import React, { useState, useRef, useEffect } from "react";
import "./Bayanaat.css";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const Jild1 = () => {
  const [audioList, setAudioList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const audioRef = useRef(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  // const [audioLoaded, setAudioLoaded] = useState(false); // Track audio loading status

  // const audioRef = useRef(null);
  // const [selectedAudio, setSelectedAudio] = useState(false);
  // const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  // const [name , setName] = useState('');
  // const audioList = [
  //   '1. Aqeedah Ki Durustagi Aur Pukhtagi.mp3',
  //   '2. Fazeelat e Sunnat.mp3',
  // ]

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedAudio) {
      audioRef.current.src = `https://dashboard.zadeashiqanemustafa.com/storage/${selectedAudio.link}`;
      audioRef.current.load();
      audioRef.current.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
    }
  }, [selectedAudio]);

  useEffect(() => {
    if (audioList.length > 0 && currentTrackIndex !== null) {
      setSelectedAudio(audioList[currentTrackIndex]);
      // Reset audio loading status
    }
  }, [currentTrackIndex]);

  const fetchData = async () => {
    let apiURL = "https://dashboard.zadeashiqanemustafa.com/api/jild-one";
    await axios
      .get(apiURL)
      .then((response) => {
        setAudioList(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (audioList.length > 0 && currentTrackIndex !== null) {
      setSelectedAudio(audioList[currentTrackIndex]);
    }
  }, [currentTrackIndex]);

  const handleAudioClick = (index) => {
    setCurrentTrackIndex(index);
    setSelectedAudio(audioList[index]);
    setName(audioList[index].name);
  };
  const handlePlay = () => {
    if (currentTrackIndex !== null) {
      setName(audioList[currentTrackIndex].name);
    }
  };

  const handleEnded = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % audioList.length);
  };
  return (
    // <div className="audio-container1">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 d-flex justify-content-center  ">
          {loading ? (
            <div className="backdrop" style={{ backgroundColor: "#1C3D69" }}>
              <div className="spinner-container">
                <Spinner
                  animation="border"
                  variant="light"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
          ) : (
            // <section className='audio-section1'>
            <div className="text-center mt-5">
              {audioList.length === 0 ? (
                <center>
                  {" "}
                  <h1>No Audio Found</h1>
                </center>
              ) : (
                <>
                  <h5 className="now-playing-text px-5">Now Playing: {name}</h5>{" "}
                  <audio
                    ref={audioRef}
                    controls
                    autoPlay
                    onEnded={handleEnded}
                    onPlay={handlePlay}
                    className="Commonforeverscreen"
                  >
                    {selectedAudio && (
                      <source
                        src={`https://dashboard.zadeashiqanemustafa.com/storage/${selectedAudio.link}`}
                        type="audio/mp3"
                      />
                    )}
                  </audio>
                </>
              )}
            </div>
          )}
        </div>

        <div className="col-lg-6 ">
            <div className="bayanaat-listing-container"></div>

        <ol className="listing-audio1">
          {audioList.map((audio, index) => (
            <li
              key={audio.id}
              onClick={() => handleAudioClick(index)}
              className={`${
                selectedAudio === audio ? "active" : ""
              } liststyle`}
            >
              {audio.name}
            </li>
          ))}
        </ol>
        </div>
      </div>
    </div>
  );
};

export default Jild1;
