import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "./Bayanaat.css";
const Jild4 = () => {
  const [audioList, setAudioList] = useState([]);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef(null);
  const [selectedAudio, setSelectedAudio] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [name, setName] = useState("");

  const fetchData = async () => {
    let apiURL = "https://dashboard.zadeashiqanemustafa.com/api/jild-six";
    axios
      .get(apiURL)
      .then((response) => {
        setAudioList(response.data);
      })
      .catch((Err) => {
        console.log(Err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedAudio) {
      audioRef.current.src = `https://dashboard.zadeashiqanemustafa.com/storage/${selectedAudio.link}`;
      audioRef.current.load();
      audioRef.current.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
    }
  }, [selectedAudio]);

  useEffect(() => {
    if (
      audioList.length > 0 &&
      currentTrackIndex >= 0 &&
      currentTrackIndex < audioList.length
    ) {
      setSelectedAudio(audioList[currentTrackIndex]);
    }
  }, [currentTrackIndex]);
  useEffect(() => {
    if (audioList.length > 0 && currentTrackIndex !== null) {
      setSelectedAudio(audioList[currentTrackIndex]);
    }
  }, [currentTrackIndex]);

  const handleAudioClick = (index) => {
    setCurrentTrackIndex(index);
    setName(audioList[index].name);
  };
  const handlePlay = () => {
    if (currentTrackIndex !== null) {
      setName(audioList[currentTrackIndex].name);
    }
  };

  const handleEnded = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % audioList.length);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center  ">
            {loading ? (
              <div className="backdrop" style={{ backgroundColor: "#1C3D69" }}>
                <div className="spinner-container">
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center mt-5">
                {audioList.length === 0 ? (
                  <h1>No Audio Found</h1>
                ) : (
                  <>
                    <h5 className="now-playing-text px-5">Now Playing: {name}</h5>
                    <audio
                      ref={audioRef}
                      controls
                      autoPlay
                      onEnded={handleEnded}
                      onPlay={handlePlay}
                      className="Commonforeverscreen"
                    >
                      {selectedAudio && (
                        <source
                          src={`https://dashboard.zadeashiqanemustafa.com/storage/${selectedAudio.link}`}
                          type="audio/mp3"
                        />
                      )}
                    </audio>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="col-lg-6 ">
            <div className="bayanaat-listing-container">
              <ol className="listing3">
                {audioList.map((audio, index) => (
                  <li
                    key={audio.id}
                    onClick={() => handleAudioClick(index)}
                    className={`${
                      selectedAudio === audio ? "active" : ""
                    } liststyle`}
                  >
                    {audio.name}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jild4;
