import React from 'react'
import {Route , Routes , Navigate,} from 'react-router-dom';
import Home from '../Pages/Home';
import AudioBook from '../Pages/AudioBook';
import Bayanaat from '../Pages/Bayanaat';
import Naat from '../Pages/Naat';
import ContactUs from '../Pages/ContactUs';
import Jild1 from '../Pages/JildOne';
import Jild2 from '../Pages/JildTwo';
import Jild3 from '../Pages/JildThree';
import Jild4 from '../Pages/JildFour';
import Jild5 from '../Pages/JildFive';
import Jild6 from '../Pages/jildSix';

const Router = () => {
  return (

    <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/home' element={<Home />} />
    <Route path='/audiobook' element={<AudioBook />} />
    <Route path='/bayanaat' element={<Bayanaat />} />
    <Route path='/naat' element={<Naat />} />
    <Route path='/contact' element={<ContactUs />} />
    <Route path='/jild1' element={<Jild1 />} />
    <Route path='/jild2' element={<Jild2 />} />
    <Route path='/jild3' element={<Jild3 />} />
    <Route path='/jild4' element={<Jild4 />} />
    <Route path='/jild5' element={<Jild5 />} />
    <Route path='/jild6' element={<Jild6 />} />

    </Routes>
  )
}

export default Router
