import React, { useState } from 'react';
import './ContactUs.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactUs = () => {
  const initialFormData = {
    name: '',
    email: '',
    number: '',
    subject: '',
    message: '',
  };

  const [input, setInput] = useState(initialFormData);

  const InputChangeHandler = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    // Validation: Check if all required fields are filled
    if (!input.name || !input.email || !input.subject || !input.message || !input.number) {
      toast.warning('Please fill in all required fields');
    } else {
      // Submit the form data
      axios
        .post('/api/contact-us', input, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          toast.success('Your feedback was successfully received');
          const responseData = response.data;
          console.log(responseData)
          setInput(initialFormData); // Clear the input fields
        })
        .catch((err) => {
          console.log(err);
          toast.error('An error occurred while submitting the form');
        });
    }
  };

  return (
    <div>
      <section className="contact-sec">
        <ToastContainer />
        <div className="gap">
          <div className="container">
            <div className="contact-info-wrap text-center remove-ext3">
              <div className="row justify-content-center mt-5 mb-5">
                <div className="col-md-8 col-sm-12 col-lg-8">
                  <div className="sec-title text-center" style={{ marginBottom: 0 }}>
                    <div className="sec-title-inner">
                      <span>Have Question</span>
                      <h3 style={{ color: 'black' }}>Send Feedback</h3>
                    </div>
                  </div>
                  <div className="contact-form text-center" style={{ marginTop: 0 }}>
                    <form onSubmit={submitHandler}>
                      <div className="row mrg20">
                        <div className="col-md-6 col-sm-6 col-lg-6">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={InputChangeHandler}
                            value={input.name}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your email"
                            onChange={InputChangeHandler}
                            value={input.email}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6">
                          <input
                            type="text"
                            name="number"
                            placeholder="Phone"
                            onChange={InputChangeHandler}
                            value={input.number}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            onChange={InputChangeHandler}
                            value={input.subject}
                          />
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12">
                          <textarea
                            cols={4}
                            name="message"
                            rows={4}
                            placeholder="Write your message here"
                            onChange={InputChangeHandler}
                            value={input.message}
                          />
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12">
                          <button style={{background:'#d1ad3c' , color:'white', border: 'none'}} className="thm-btn brd-rd40 mt-4" type="submit">
                            Contact Us
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
