import React from 'react';
import Header from '../Header/Header';
import Router from '../Routes/Router';
import Footer from '../Footer/Footer';

const Layout = () => {


  return (
    <>
     <Header />
      <Router />    
     <Footer />
     </>
    
    
  );
};

export default Layout;
