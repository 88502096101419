import React from 'react'
import './AudioBook.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
const AudioBook = () => {
  let navigate = useNavigate();
  function CardHandler1() {
     navigate('/jild1')
  }
   function CardHandler2() {
    navigate('/jild2')
   }
   function CardHandler3() {
    navigate('/jild3')
   }
   function CardHandler4()  {
    navigate('/jild4')
   }

    function CardHandler5()  {
        navigate('/jild5')
    }

    function CardHandler6()  {
        navigate('/jild6')
    }
  return (
  <>
      <section className='container'>
          <div className="card" onClick={CardHandler1}
               style={{width: '24rem', backgroundColor: 'dimgrey', color: 'white'}}>
              <center>
                  <img src='./images/20230307_135954.png' alt='logo'/>
              </center>

              <div className="card-body">
                  <h2 className="card-title">Jild 1</h2>
              </div>
          </div>

          <div className="card" onClick={CardHandler2}
               style={{width: '24rem', backgroundColor: 'dimgrey', color: 'white'}}>
              <center>
                  <img src='./images/20230307_135954.png' alt='logo'/>
              </center>
              <div className="card-body">
                  <h2 className="card-title">Jild 2</h2>
              </div>
          </div>
          <div className="card" onClick={CardHandler3}
               style={{width: '24rem', backgroundColor: 'dimgrey', color: 'white'}}>
              <center>
                  <img src='./images/20230307_135954.png' alt='logo'/>
              </center>
              <div className="card-body">
                  <h2 className="card-title">Jild 3</h2>
              </div>
          </div>
          <div className="card" onClick={CardHandler4}
               style={{width: '24rem', backgroundColor: 'dimgrey', color: 'white'}}>
              <center>
                  <img src='./images/20230307_135954.png' alt='logo'/>
              </center>
              <div className="card-body">
                  <h2 className="card-title">Jild 4</h2>
              </div>
          </div>

          <div className="card" onClick={CardHandler5}
               style={{width: '24rem', backgroundColor: 'dimgrey', color: 'white'}}>
              <center>
                  <img src='./images/20230307_135954.png' alt='logo'/>
              </center>
              <div className="card-body">
                  <h2 className="card-title">Jild 5</h2>
              </div>
          </div>

          <div className="card" onClick={CardHandler6}
               style={{width: '24rem', backgroundColor: 'dimgrey', color: 'white'}}>
              <center>
                  <img src='./images/20230307_135954.png' alt='logo'/>
              </center>
              <div className="card-body">
                  <h2 className="card-title">Jild 6</h2>
              </div>
          </div>
      </section>
  </>
  )
}

export default AudioBook
