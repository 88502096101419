import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SelectMenu.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const SelectMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const [isActive, setIsActive] = useState(location.pathname === '/' ? '/home' : location.pathname);

  const handleSelectClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleLinkClick = (path) => {
    setIsActive(path); 
    setOpenMenu(false);
  };

  useEffect(() => {
    setIsActive(location.pathname === '/' ? '/home' : location.pathname);
  }, [location.pathname]);

  const getCapitalizedLabel = (path) => {
    return path.charAt(1).toUpperCase()+path.slice(2)
  }
  return (
    <div className="select-menu">
      <div className="select-btn" onClick={handleSelectClick}>
        <span className="Btn-text">{getCapitalizedLabel(isActive)}</span>
        <i className="fa fa-chevron-down icon-right" />
      </div>
      {openMenu && (
        <ul className="options">
          <li className="option-text">
            <Link className={isActive === '/home' ? 'option-text active' : 'option-text'} to="/home" data-value="Home" onClick={() => handleLinkClick('/home')}>Home</Link>
          </li>
          <li className="option-text">
            <Link className={isActive === '/audiobook' ? 'option-text active' : 'option-text'} to="/audiobook" data-value="AudioBook" onClick={() => handleLinkClick('/audiobook')}>Audio Book</Link>
          </li>
          <li className="option-text">
            <Link className={isActive === '/bayanaat' ? 'option-text active' : 'option-text'} to="/bayanaat" data-value="Bayanaat" onClick={() => handleLinkClick('/bayanaat')}>Bayanat</Link>
          </li>
          <li className="option-text">
            <Link className={isActive === '/naat' ? 'option-text active' : 'option-text'} to="/naat" data-value="Naatain" onClick={() => handleLinkClick('/naat')}>Naatain</Link>
          </li>
          <li className="option-text">
            <Link className={isActive === '/contact' ? 'option-text active' : 'option-text'} to="/contact" data-value="Contact" onClick={() => handleLinkClick('/contact')}>Contact Us</Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SelectMenu;
