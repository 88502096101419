import React, { useState } from 'react';
import './Nav.css';
import { Link, useLocation } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  // Set the default active link to '/Home'
  useState(() => {
    setActiveLink('/home');
  }, []);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <ul className="Links">
      <li>
        <Link className={activeLink === '/home' ? 'active' : ''} to="/home" onClick={() => handleLinkClick('/home')}>
          Home
        </Link>
      </li>
      <li>
        <Link className={activeLink === '/audiobook' ? 'active' : ''} to="/audiobook" onClick={() => handleLinkClick('/audiobook')}>
          Audio Book
        </Link>
      </li>
      <li>
        <Link className={activeLink === '/bayanaat' ? 'active' : ''} to="/bayanaat" onClick={() => handleLinkClick('/bayanaat')}>
          Bayanat
        </Link>
      </li>
      <li>
        <Link className={activeLink === '/naat' ? 'active' : ''} to="/naat" onClick={() => handleLinkClick('/naat')}>
          Naatain
        </Link>
      </li>
      <li>
        <Link className={activeLink === '/contact' ? 'active' : ''} to="/contact" onClick={() => handleLinkClick('/contact')}>
          Contact Us
        </Link>
      </li>
    </ul>
  );
};

export default Nav;
