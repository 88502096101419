import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer-container'>
      <footer className="footer">
        <div className='left-container'>
          <ul>
            <li><Link to='/contact-us' className='contact'>Contact Us</Link></li>
          </ul>
        </div>
        <div className='right-container'>
          Copyright &copy; 2023 <Link to='https://zadeashiqanemustafa.com/'>Zade Ashiqane Mustafa.</Link> 
          <span> All rights reserved. Developed by <Link to='https://coralr.com/'>Coral Reef</Link></span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
